import React, { useRef, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { SliderArrowIcon } from "../components";

const AppSlider = ({
  adaptiveHeight,
  arrows,
  autoplay,
  autoplaySpeed,
  className,
  children,
  dots,
  hideArrowsTablet,
  initialSlide,
  moreMargins,
  noNegativeMargins,
  responsive,
  slidesToShow,
  slidesToScroll,
  slideIndex,
  swipeToSlide,
  setSlideIndex,
}) => {
  // Default settings for all sliders
  // https://react-slick.neostack.com/docs/api
  const defaultSettings = {
    infinite: true,
    speed: 500,
    initialSlide: initialSlide || 0,
    autoplay: autoplay || false,
    autoplaySpeed: autoplaySpeed || 3000,
    arrows: arrows || true,
    prevArrow: (
      <SliderArrowIcon
        left
        className="slick-prev"
        role="button"
        tabIndex={0}
        aria-label="Previous Slide"
      />
    ),
    nextArrow: (
      <SliderArrowIcon
        right
        className="slick-next"
        role="button"
        tabIndex={0}
        aria-label="Next Slide"
      />
    ),
    dots: dots || true,
    slidesToShow: slidesToShow || 1,
    slidesToScroll: slidesToScroll || 1,
    swipeToSlide: swipeToSlide || false,
    adaptiveHeight: adaptiveHeight || false,
    // afterChange: () => doSomething(),
    beforeChange: (current, next) => setSlideIndex && setSlideIndex(next),
    responsive: responsive || [
      {
        breakpoint: 1335,
        settings: {
          slidesToShow: slidesToShow >= 4 ? 4 : slidesToShow || 1,
          slidesToScroll: slidesToScroll >= 4 ? 4 : slidesToShow || 1,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: slidesToShow >= 3 ? 3 : slidesToShow || 1,
          slidesToScroll: slidesToScroll >= 3 ? 3 : slidesToShow || 1,
          arrows: hideArrowsTablet ? false : true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: slidesToShow >= 2 ? 2 : slidesToShow || 1,
          slidesToScroll: slidesToScroll >= 2 ? 2 : slidesToShow || 1,
          arrows: false,
        },
      },
      {
        breakpoint: 549,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const sliderRef = useRef(null);
  useEffect(() => {
    sliderRef.current.slickGoTo(slideIndex);
  }, [slideIndex]);

  return (
    <Slider
      ref={sliderRef}
      className={`app-slider${className ? ` ${className}` : ""}${
        moreMargins ? ` more-margins` : ""
      }${noNegativeMargins ? ` no-negative-margins` : ""}`}
      {...defaultSettings}
    >
      {children}
    </Slider>
  );
};

export default AppSlider;
