import React from "react";

const CloseIcon = (props) => {
  return (
    <div {...props} className={"icon-wrapper close-icon"}>
      <svg
        // width={props.width || "100%"}
        // height={props.height || "100%"}
        role="none"
        height="100%"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 1L21 21M21 1L1 21" stroke="currentColor" />
      </svg>
    </div>
  );
};

export default CloseIcon;
