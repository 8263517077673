import React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import _ from "lodash";

import formatLinkHandle from "../utils/formatLinkHandle";

import {
  AppImage,
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  // LogoIcon,
  // LogoTitle,
  TwitterIcon,
} from "../components";

const Footer = () => {
  const { contentfulSiteData } = useStaticQuery(
    graphql`
      query {
        contentfulSiteData {
          logo {
            title
            fluid(maxWidth: 100, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          footerAbout
          socialLinks {
            title
            url
          }
          footerLinks {
            title
            handle
          }
          locations {
            title
            addressLine2
            addressLine1
          }
          contactEmails {
            title
            email
          }
          companyName
        }
      }
    `
  );

  const {
    footerAbout,
    locations,
    socialLinks,
    footerLinks,
    contactEmails,
    companyName,
  } = contentfulSiteData;

  return (
    <footer>
      <section>
        <div className="container flex phablet">
          <div className="logo-column">
            <Link className="logo-wrapper" to="/" aria-label="Logo">
              <AppImage
                className="logo"
                image={_.get(contentfulSiteData, "logo")}
              />
              {/*<LogoIcon />
              <LogoTitle />*/}
            </Link>
            <h3 className="p about">{footerAbout}</h3>
            <div className="social-row">
              {_.find(socialLinks, ["title", "Facebook"]) ? (
                <a
                  href={_.get(
                    _.find(socialLinks, ["title", "Facebook"]),
                    "url"
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Facebook"
                >
                  <FacebookIcon />
                </a>
              ) : null}
              {_.find(socialLinks, ["title", "Instagram"]) ? (
                <a
                  href={_.get(
                    _.find(socialLinks, ["title", "Instagram"]),
                    "url"
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Instagram"
                >
                  <InstagramIcon />
                </a>
              ) : null}
              {_.find(socialLinks, ["title", "LinkedIn"]) ? (
                <a
                  href={_.get(
                    _.find(socialLinks, ["title", "LinkedIn"]),
                    "url"
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="LinkedIn"
                >
                  <LinkedInIcon />
                </a>
              ) : null}
              {_.find(socialLinks, ["title", "Twitter"]) ? (
                <a
                  href={_.get(_.find(socialLinks, ["title", "Twitter"]), "url")}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Twitter"
                >
                  <TwitterIcon />
                </a>
              ) : null}
            </div>
          </div>
          <div
            className={`inquiries-column${
              contactEmails.length <= locations.length ? " align" : ""
            }`}
          >
            {contactEmails.map((contactEmail, index) => {
              return (
                <div key={index} className="inquiries-info">
                  <h4>{contactEmail.title}</h4>
                  <p>
                    <a
                      className="footer-link"
                      href={`mailto:${contactEmail.email}`}
                    >
                      {contactEmail.email}
                    </a>
                  </p>
                </div>
              );
            })}
          </div>
          <div
            className={`locations-column${
              contactEmails.length <= locations.length ? " align" : ""
            }`}
          >
            {locations.map((location, index) => {
              return (
                <div key={index} className="inquiries-info">
                  <h4>{location.title}</h4>
                  <p className="footer-text">
                    {location.addressLine1}
                    {<br />}
                    {location.addressLine2}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="container flex phablet">
          <div className="terms-links container flex phablet">
            {footerLinks.map((link, index) => {
              return (
                <Link
                  key={index}
                  to={formatLinkHandle(link.handle)}
                  className="terms-link footer-link"
                >
                  {link.title}
                </Link>
              );
            })}
          </div>
          <div className="copyright footer-text desktop">
            © {new Date().getFullYear()} {companyName}, All Rights Reserved
          </div>
          <div className="copyright footer-text mobile">
            © {new Date().getFullYear()} {companyName}
          </div>
          <div className="copyright footer-text mobile">
            All Rights Reserved
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
