import React, { useState } from "react";
import { EllipseIcon } from "../components";

const ToggleCardBasic = (props) => {
  const [expand, setExpand] = useState(0);
  function handleClick(id) {
    if (id === expand) {
      setExpand(0);
    } else {
      setExpand(id);
    }
  }
  return (
    <>
      <div
        className={`toggleCardBasicContainer toggleCard ${
          expand === props.data.order ? " expanded" : ""
        }`}
        role="button"
        tabIndex={0}
        aria-label={props.data.title}
        onClick={() => handleClick(props.data.order)}
        onKeyPress={() => handleClick(props.data.order)}
      >
        <h4 className="toggleCardBasicTitle">{props.data.title}</h4>
        {expand === props.data.order && (
          <div
            className="toggleCardBasicDescription"
            dangerouslySetInnerHTML={{
              __html: props.data.description.childMarkdownRemark.html,
            }}
          />
        )}
        {expand !== props.data.order && (
          <EllipseIcon className="toggleCardBasicEllipse" />
        )}
      </div>
    </>
  );
};

export default ToggleCardBasic;
