import React from "react";

const InstagramIcon = (props) => {
  return (
    <div {...props} className={"icon-wrapper instagram-icon"}>
      <svg
        role="none"
        width="100%"
        height="100%"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Footer ig/Default">
          <path
            id="Vector"
            d="M13.8457 0H6.16505C2.7616 0 0 2.7616 0 6.16505V13.8403C0 17.2438 2.7562 20 6.16505 20H13.8403C17.2438 20 20 17.2438 20 13.8403V6.16505C20.0054 2.7562 17.2492 0 13.8457 0ZM18.0852 13.1014C18.0852 15.8522 15.8576 18.0798 13.1068 18.0798H6.90399C4.15318 18.0798 1.92557 15.8522 1.92557 13.1014V6.8986C1.92557 4.14779 4.15318 1.92017 6.90399 1.92017H13.1068C15.8576 1.92017 18.0852 4.15318 18.0852 6.8986V13.1014Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            d="M10.2736 4.76758C7.36097 4.76758 5.00391 7.12464 5.00391 10.0373C5.00391 12.9445 7.36097 15.307 10.2736 15.307C13.1808 15.307 15.5433 12.9499 15.5433 10.0373C15.5379 7.12464 13.1808 4.76758 10.2736 4.76758ZM10.2736 13.2034C8.52063 13.2034 7.10207 11.7848 7.10207 10.0319C7.10207 8.2789 8.52063 6.86035 10.2736 6.86035C12.0212 6.86035 13.4451 8.2789 13.4451 10.0319C13.4397 11.7848 12.0212 13.2034 10.2736 13.2034Z"
            fill="currentColor"
          />
          <path
            id="Vector_3"
            d="M16.5151 4.68679C16.5151 5.42573 15.9164 6.02444 15.1775 6.02444C14.4385 6.02444 13.8398 5.42573 13.8398 4.68679C13.8398 3.94785 14.4385 3.34375 15.1775 3.34375C15.9164 3.34375 16.5151 3.94246 16.5151 4.68679Z"
            fill="currentColor"
          />
        </g>
      </svg>
    </div>
  );
};

export default InstagramIcon;
