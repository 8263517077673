import React from "react";

const MissingAuthorIcon = (props) => {
  return (
    <div {...props} className={"icon-wrapper missing-author-icon"}>
      <svg
        role="none"
        width="100%"
        height="100%"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="30" cy="30" r="30" fill="white" />
        <mask
          id="mask0"
          // style="mask-type:alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="60"
          height="60"
        >
          <circle cx="30" cy="30" r="30" fill="#C4C4C4" />
        </mask>
        <g mask="url(#mask0)">
          <circle cx="30" cy="22" r="15.5" stroke="#55A8BC" strokeWidth="3" />
          <path
            d="M53.5 53C53.5 57.0646 51.1273 60.9031 46.969 63.7884C42.8163 66.6699 36.9983 68.5 30.5 68.5C24.0017 68.5 18.1837 66.6699 14.031 63.7884C9.87269 60.9031 7.5 57.0646 7.5 53C7.5 48.9354 9.87269 45.0969 14.031 42.2116C18.1837 39.3301 24.0017 37.5 30.5 37.5C36.9983 37.5 42.8163 39.3301 46.969 42.2116C51.1273 45.0969 53.5 48.9354 53.5 53Z"
            stroke="#55A8BC"
            strokeWidth="3"
          />
          <circle cx="30.2767" cy="15.3939" r="5.89388" stroke="#55A8BC" />
          <circle cx="30.2767" cy="21.7878" r="5.89388" stroke="#55A8BC" />
          <circle cx="30.2767" cy="28.1816" r="5.89388" stroke="#55A8BC" />
          <circle
            cx="35.7411"
            cy="18.4679"
            r="5.89387"
            transform="rotate(58.7202 35.7411 18.4679)"
            stroke="#55A8BC"
          />
          <circle
            cx="24.8121"
            cy="25.1076"
            r="5.89387"
            transform="rotate(58.7202 24.8121 25.1076)"
            stroke="#55A8BC"
          />
          <circle
            cx="35.834"
            cy="24.9495"
            r="5.89388"
            transform="rotate(119.637 35.834 24.9495)"
            stroke="#55A8BC"
          />
          <circle
            cx="24.7191"
            cy="18.626"
            r="5.89388"
            transform="rotate(119.637 24.7191 18.626)"
            stroke="#55A8BC"
          />
        </g>
      </svg>
    </div>
  );
};

export default MissingAuthorIcon;
