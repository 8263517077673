import React from "react";

const FacebookIcon = (props) => {
  return (
    <div {...props} className={"icon-wrapper facebook-icon"}>
      <svg
        // width={props.width || "100%"}
        // height={props.height || "100%"}
        role="none"
        height="100%"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Footer Facebook/Default">
          <path
            id="Subtract"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.75 20C19.44 20 20 19.44 20 18.75V1.25C20 0.56 19.44 0 18.75 0H1.25C0.56 0 0 0.56 0 1.25V18.75C0 19.44 0.56 20 1.25 20H10.625V12.5H8.125V9.375H10.625V7.5C10.625 4.92562 12.0781 3.125 14.375 3.125H16.875V6.25H15.625C14.3763 6.25 13.75 6.875 13.75 8.125V9.375H16.875L16.25 12.5H13.75V20H18.75Z"
            fill="currentColor"
          />
        </g>
      </svg>
    </div>
  );
};

export default FacebookIcon;
