import React from "react";

const LinkedInIcon = (props) => {
  return (
    <div {...props} className={"icon-wrapper linkedin-icon"}>
      <svg
        role="none"
        width="100%"
        height="100%"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Footer Linkedin/Default">
          <path
            id="Vector"
            d="M10.6881 9.0212V8.99023C10.6816 9.00069 10.6748 9.01114 10.668 9.0212H10.6881Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            d="M18.4048 0H1.46809C0.657445 0 0 0.641763 0 1.43271V18.5677C0 19.3582 0.657445 20 1.46809 20H18.4048C19.2167 20 19.8741 19.3578 19.8741 18.5677V1.43271C19.8741 0.641361 19.2163 0 18.4048 0ZM6.02396 16.7425H3.02224V7.71161H6.02396V16.7425ZM4.5233 6.47794H4.5032C3.49632 6.47794 2.84491 5.78431 2.84491 4.91777C2.84491 4.03193 3.51643 3.35759 4.543 3.35759C5.56998 3.35759 6.20169 4.03193 6.2218 4.91777C6.2218 5.78431 5.56958 6.47794 4.5233 6.47794ZM16.8483 16.7425H13.8466V11.91C13.8466 10.6964 13.4123 9.86811 12.3262 9.86811C11.497 9.86811 11.0033 10.4266 10.7857 10.9663C10.7065 11.1589 10.6872 11.4283 10.6872 11.6985V16.7421H7.68547C7.68547 16.7421 7.72488 8.55764 7.68547 7.71121H10.6872V8.98951C11.0861 8.37428 11.7994 7.49809 13.393 7.49809C15.3677 7.49809 16.8487 8.78926 16.8487 11.5634L16.8483 16.7425Z"
            fill="currentColor"
          />
        </g>
      </svg>
    </div>
  );
};

export default LinkedInIcon;
