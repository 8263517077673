import React from "react";
import Img from "gatsby-image";
import _ from "lodash";

const AppImage = ({ aspectRatio, image, imgStyle, style, className, flex }) => {
  if (_.get(image, "file.url")) {
    return (
      <div className={`icon-wrapper${className ? ` ${className}` : ""}`}>
        <img
          role="none"
          alt={_.get(image, "title")}
          src={_.get(image, "file.url")}
          height="100%"
          width="100%"
          style={style}
        />
      </div>
    );
  } else if (_.get(image, "fixed")) {
    return (
      <Img
        className={`app-image${className ? " " + className : ""}${
          flex ? " flex" : ""
        }`}
        role="none"
        alt={_.get(image, "title")}
        fixed={
          aspectRatio
            ? { ..._.get(image, "fixed"), aspectRatio }
            : _.get(image, "fixed")
        }
        imgStyle={imgStyle}
      />
    );
  } else {
    return (
      <Img
        className={`app-image${className ? " " + className : ""}${
          flex ? " flex" : ""
        }`}
        role="none"
        alt={_.get(image, "title")}
        fluid={
          aspectRatio
            ? { ..._.get(image, "fluid"), aspectRatio }
            : _.get(image, "fluid")
        }
        imgStyle={imgStyle}
      />
    );
  }
};

export default AppImage;
