import React from "react";

const SliderArrowIcon = (props) => {
  const { className, left } = props;
  return (
    <div
      {...props}
      className={`icon-wrapper slider-arrow-icon${
        className ? ` ${className}` : ""
      }`}
    >
      <svg
        // width={width || "100%"}
        // height={height || "100%"}
        role="none"
        height="100%"
        viewBox="0 0 12 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L11 11L1 21"
          stroke="currentColor"
          transform={left ? "rotate (180, 6, 11)" : ""}
        />
      </svg>
    </div>
  );
};

export default SliderArrowIcon;
