import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import _ from "lodash";

import { DownArrowIcon } from "../components";
import getWindowWidth from "../utils/getWindowWidth";

const Pagination = ({
  array,
  arraySliced,
  setArraySliced,
  pageRangeDisplayed,
}) => {
  const pageCount = Math.ceil(_.get(array, "length") / pageRangeDisplayed);
  const [page, setPage] = useState(0);

  const handlePageClick = (data) => {
    const selected = data.selected;
    const offset = Math.ceil(selected * pageRangeDisplayed);
    const slidedArray = array.slice(offset, offset + pageRangeDisplayed);
    setArraySliced(slidedArray);
    setPage(selected);
  };

  const handleSeeMoreClick = () => {
    const slidedArray = array.slice(0, arraySliced.length + pageRangeDisplayed);
    setArraySliced(slidedArray);
  };

  const windowWidth = getWindowWidth();

  useEffect(() => {
    if (windowWidth < 1024) {
      if (arraySliced[0] !== array[0]) {
        setArraySliced(array.slice(0, pageRangeDisplayed));
      }
    } else {
      if (arraySliced.length > pageRangeDisplayed) {
        setArraySliced(array.slice(0, pageRangeDisplayed));
      }
      setPage(0);
    }
  }, [windowWidth]);

  return (
    <div className="pagination">
      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={pageRangeDisplayed}
        onPageChange={handlePageClick}
        forcePage={page}
        marginPagesDisplayed={2}
        initialSelected={6}
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        containerClassName={"react-paginate"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
      <button
        className={`see-more${
          arraySliced.length < array.length ? "" : " hide"
        }`}
        onClick={handleSeeMoreClick}
      >
        See More <DownArrowIcon className="down-arrow-icon" />
      </button>
    </div>
  );
};

export default Pagination;
