import React from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import _ from "lodash";
import { AppImage } from "../components";

const RichTextRender = ({ contentfulRichText }) => {
  // function to handle images for the render options
  const handleImages = (node) => {
    return (
      <>
        <AppImage className="image-asset" image={_.get(node, "data.target")} />
        <div className="caption">{_.get(node, "data.target.description")}</div>
      </>
    );
  };

  // render Options for how to handle assets
  const options = {
    renderNode: {
      "embedded-asset-block": handleImages,
    },
  };

  // return rich text form contentful being parsed as JSX
  return (
    <div className="rich-text">
      {renderRichText(contentfulRichText, options)}
    </div>
  );
};

export default RichTextRender;
