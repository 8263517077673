const processDate = (date) => {
  const dateObject = new Date(date);
  // const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
  // const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const months = [
    "Jan.",
    "Feb.",
    "Mar.",
    "Apr.",
    "May",
    "Jun.",
    "Jul.",
    "Aug.",
    "Sep.",
    "Oct.",
    "Nov.",
    "Dec.",
  ];
  const month = months[dateObject.getUTCMonth()];
  const day = dateObject.getUTCDate();
  const year = dateObject.getUTCFullYear();
  // const currentDate = new Date();
  // const currentYear = currentDate.getUTCFullYear();
  // year !== currentYear ? `, ${year}` : ""
  return `${month} ${day}, ${year}`;
};

export default processDate;
