import React from "react";
import { ToggleCardBasic, ToggleCardAdvanced } from "../components";

const ToggleCards = (props) => {
  switch (props.type) {
    case "basic":
      return <ToggleCardBasic data={props.data} />;
    case "advanced":
      return (
        <ToggleCardAdvanced
          icon={props.icon}
          iconClass={props.iconClass}
          data={props.data}
          tablet={props.tablet}
        />
      );
    default:
      return (
        <ToggleCardBasic
          data={{ title: "Dummy Card", description: "Dummy Card" }}
        />
      );
  }
};

export default ToggleCards;
