import React from "react";

const MissingHeroImage = (props) => {
  return (
    <div {...props} className="hero-image icon-wrapper">
      <svg
        role="none"
        width="100%"
        height="100%"
        viewBox="0 0 674 386"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="336.924"
          cy="41.8939"
          r="150.394"
          fill="white"
          fillOpacity="0.2"
          stroke="#55A8BC"
        />
        <circle
          cx="336.924"
          cy="192.788"
          r="150.394"
          fill="white"
          fillOpacity="0.2"
          stroke="#55A8BC"
        />
        <circle
          cx="336.924"
          cy="343.682"
          r="150.394"
          fill="white"
          fillOpacity="0.2"
          stroke="#55A8BC"
        />
        <circle
          cx="465.885"
          cy="114.441"
          r="150.394"
          transform="rotate(58.7202 465.885 114.441)"
          fill="white"
          fillOpacity="0.2"
          stroke="#55A8BC"
        />
        <circle
          cx="207.964"
          cy="271.135"
          r="150.394"
          transform="rotate(58.7202 207.964 271.135)"
          fill="white"
          fillOpacity="0.2"
          stroke="#55A8BC"
        />
        <circle
          cx="468.078"
          cy="267.404"
          r="150.394"
          transform="rotate(119.637 468.078 267.404)"
          fill="white"
          fillOpacity="0.2"
          stroke="#55A8BC"
        />
        <circle
          cx="205.77"
          cy="118.171"
          r="150.394"
          transform="rotate(119.637 205.77 118.171)"
          fill="white"
          fillOpacity="0.2"
          stroke="#55A8BC"
        />
      </svg>
    </div>
  );
};

export default MissingHeroImage;
