import React, { useState, useEffect } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { Link } from "gatsby";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { useStaticQuery, graphql } from "gatsby";
import _ from "lodash";

import { BackgroundIcon, AppImage } from "../components";
import formatLinkHandle from "../utils/formatLinkHandle";

import slugify from "../utils/slugify";

const Navbar = () => {
  const [isBrowser, setIsBrowser] = useState(false);
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [navbarOpacity, setNavbarOpacity] = useState(0);
  const { contentfulSiteData } = useStaticQuery(
    graphql`
      query {
        contentfulSiteData {
          logo {
            title
            fluid(maxWidth: 200, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  );

  useEffect(() => {
    if (navbarOpen) {
      // document.body.style.overflow = "hidden"
      disableBodyScroll(document);
    }
    return () => {
      // document.body.style.overflow = "unset"
      enableBodyScroll(document);
    };
  }, [navbarOpen]);

  const setShowMenuDefault = () => {
    enableBodyScroll(document);
    setNavbarOpen(false);
  };

  const toggleMenu = () => {
    navbarOpen ? enableBodyScroll(document) : disableBodyScroll(document);
    setNavbarOpen(!navbarOpen);
  };

  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y <= 180) {
        setNavbarOpacity(currPos.y / 200);
      } else {
        setNavbarOpacity(0.9);
      }
    },
    [isBrowser], // deps
    false, // element
    true, // useWindow
    100 // wait
  );

  useEffect(() => {
    setIsBrowser(typeof window !== "undefined");
  }, []);

  const NavLink = (title) => {
    const handle = "/" + slugify(title) + "/";
    return (
      <div className="nav-link-container">
        <Link
          className={
            isBrowser && window.location.href.indexOf(slugify(title)) > -1
              ? "nav-link small current"
              : "nav-link small"
          }
          to={formatLinkHandle(handle)}
          onClick={setShowMenuDefault}
        >
          {title}
        </Link>
      </div>
    );
  };

  const NavbarLinks = () => {
    return (
      <React.Fragment>
        {NavLink("Approach")}
        {NavLink("Science")}
        {NavLink("Care Delivery")}
        {NavLink("Pipeline")}
        {NavLink("Team")}
        {NavLink("News & Views")}
        {NavLink("Blog")}
      </React.Fragment>
    );
  };

  return (
    <nav
      className={navbarOpen ? "open" : ""}
      style={{
        backgroundColor: navbarOpen
          ? "rgba(12, 33, 66, 100)"
          : `rgba(12, 33, 66, ${navbarOpacity})`,
      }}
    >
      {/*<Link className="logo-link" to="/" aria-label="Logo">*/}
      <Link
        className="logo-link"
        to="/"
        onClick={setShowMenuDefault}
        aria-label="Logo"
      >
        {/*<Logo />*/}
        <AppImage className="logo" image={_.get(contentfulSiteData, "logo")} />
      </Link>
      <div
        className={navbarOpen ? "toggle open" : "toggle"}
        role="button"
        aria-label="Toggle Menu"
        tabIndex={0}
        onClick={toggleMenu}
        onKeyPress={toggleMenu}
      >
        <div className={navbarOpen ? "hamburger open" : "hamburger"} />
      </div>
      <div className={navbarOpen ? "mobile-menu open" : "mobile-menu"}>
        {navbarOpen ? <NavbarLinks /> : null}
        <BackgroundIcon />
      </div>
      <div
        className={navbarOpen ? "nav-links-desktop open" : "nav-links-desktop"}
      >
        <NavbarLinks />
      </div>
    </nav>
  );
};

export default Navbar;
