/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  BackgroundIcon,
  Footer,
  Navbar,
  SEO,
  CookieModal,
} from "../components";

import { SkipNavLink, SkipNavContent } from "@reach/skip-nav";
import "@reach/skip-nav/styles.css";
import "../scss/skeleton.scss";

const Layout = ({
  className,
  children,
  hideHeader,
  pageData,
  pressRelease,
  fullWidthHeader,
  isIndexPage,
}) => {
  const title =
    _.get(pageData, "title.internal.content") || _.get(pageData, "title");
  const description = _.get(pageData, "description");
  const image = _.get(pageData, "image");
  const header = pressRelease
    ? _.get(pageData, "titleWithSubscripts.childMarkdownRemark.html")
      ? _.get(pageData, "titleWithSubscripts")
      : _.get(pageData, "title")
    : _.get(pageData, "header");
  const bodyText = _.get(pageData, "bodyText");
  const bodyHtml = _.get(bodyText, "childMarkdownRemark.html");
  const headerHtml = _.get(header, "childMarkdownRemark.html");
  const headerInternal = _.get(header, "internal.content");

  const [cookieModal, setCookieModal] = useState();

  useEffect(() => {
    if (localStorage.getItem("cookieModal")) {
      setCookieModal(false);
    } else {
      setCookieModal(true);
    }
  }, []);

  const cookieModalHandler = () => {
    localStorage.setItem("cookieModal", "true");
    setCookieModal(!cookieModal);
  };

  return (
    <div className={`layout${className ? " " + className : ""}`}>
      <SkipNavLink className="skip-nav-link" />
      <SEO
        title={title}
        header={header}
        description={description}
        image={image}
        isIndexPage={isIndexPage}
      />
      <Navbar />
      <main className={fullWidthHeader ? "with-full-width-header" : ""}>
        <SkipNavContent />
        <BackgroundIcon />
        {fullWidthHeader && (
          <div className="header-component">{fullWidthHeader}</div>
        )}
        {header && !hideHeader ? (
          headerHtml ? (
            <h1
              className="h2 page-header"
              dangerouslySetInnerHTML={{ __html: headerHtml }}
            />
          ) : (
            <h1 className="h2 page-header">{headerInternal || header}</h1>
          )
        ) : null}
        {bodyText && !pressRelease ? (
          <section
            className="body-text"
            dangerouslySetInnerHTML={{ __html: bodyHtml }}
          />
        ) : null}
        {children}
      </main>
      <Footer />
      {cookieModal && <CookieModal cookieModalHandler={cookieModalHandler} />}
    </div>
  );
};

export default Layout;
