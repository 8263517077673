import React, { useState } from "react";
import { Link } from "gatsby";
import _ from "lodash";
import Truncate from "react-truncate";

import { Pagination } from "../components";

import processDate from "../utils/processDate";
import formatLinkHandle from "../utils/formatLinkHandle";
import slugify from "../utils/slugify";

const Publications = ({ data, title }) => {
  const publicationsArray = _.get(data, "nodes");
  const publicationsSorted = _.orderBy(publicationsArray, "date", "desc");
  const pageRangeDisplayed = 5;
  const authorsCheck = _.filter(publicationsArray, "authors").length;
  const journalCheck = _.filter(publicationsArray, "journal").length;

  const [publicationsSliced, setPublicationsSliced] = useState(
    publicationsSorted.slice(0, pageRangeDisplayed)
  );

  const dataRow = (publication) => {
    return (
      <div className="container flex tablet data-row" role="none">
        <h3
          className={`${
            authorsCheck && journalCheck
              ? "six"
              : authorsCheck || journalCheck
              ? "eight"
              : "ten"
          } h4 columns title`}
        >
          <div
            dangerouslySetInnerHTML={{
              __html:
                _.get(
                  publication,
                  "titleWithSubscripts.childMarkdownRemark.html"
                ) || _.get(publication, "title.childMarkdownRemark.html"),
            }}
          />
        </h3>
        {authorsCheck ? (
          <p className="two columns authors">
            <Truncate lines={3} trimWhitespace>
              {_.get(publication, "authors.internal.content") || "N/A"}
            </Truncate>
          </p>
        ) : null}
        {journalCheck ? (
          <p className="two columns journal">
            <Truncate lines={3} trimWhitespace>
              {_.get(publication, "journal.internal.content") || "N/A"}
            </Truncate>
          </p>
        ) : null}
        <p className="two columns date">
          {processDate(_.get(publication, "date"))}
        </p>
      </div>
    );
  };

  return (
    <section className="publications">
      <div className="table-container">
        <div className="header">
          <h2 className="h3">{title}</h2>
        </div>
        <div className="container flex tablet headings">
          <h4 className="six columns title">Title</h4>
          {authorsCheck ? (
            <h4 className="two columns authors">Authors</h4>
          ) : (
            <div className="two columns" />
          )}
          {journalCheck ? (
            <h4 className="two columns journal">Journal</h4>
          ) : (
            <div className="two columns" />
          )}
          <h4 className="two columns date">Date</h4>
        </div>
        {publicationsSliced &&
          publicationsSliced.map((publication, index) => {
            return publication.url ? (
              <a
                key={index}
                href={_.get(publication, "url.internal.content")}
                target="_blank"
                rel="noopener noreferrer"
              >
                {dataRow(publication)}
              </a>
            ) : (
              <Link
                to={formatLinkHandle(
                  `/press-releases/${slugify(
                    _.get(publication, "title.internal.content")
                  )}/`
                )}
              >
                {dataRow(publication)}
              </Link>
            );
          })}
      </div>
      {publicationsSliced && (
        <Pagination
          array={publicationsSorted}
          arraySliced={publicationsSliced}
          setArraySliced={setPublicationsSliced}
          pageRangeDisplayed={pageRangeDisplayed}
        />
      )}
    </section>
  );
};

export default Publications;
