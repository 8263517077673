// https://andrew.stwrt.ca/posts/js-slugify/

function slugify(string) {
  return (
    string
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "")
      .replace(/--+/g, "-")
      // .replace(/[^\w\-]+/g, "")
      // .replace(/\-\-+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "")
  );
}

export default slugify;
