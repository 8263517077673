import React, { useState, useEffect, useRef } from "react";

import { AppSlider, SliderArrowIcon } from "../components";
import getWindowWidth from "../utils/getWindowWidth";

const FilterSliderSection = ({
  hideZeroOption,
  selected,
  setSelected,
  filters,
  children,
  sliderChildren,
}) => {
  const filterContainerRef = useRef(null);

  useEffect(() => {
    const current = filterContainerRef.current;
    current.scrollTo(filters[selected].offset, 0);
  }, [selected]);

  const handleFilterClick = (number) => {
    if (
      (hideZeroOption && number === selected) ||
      number > filters.length - 1
    ) {
      setSelected(0);
    } else {
      setSelected(number);
    }
  };

  const windowWidth = getWindowWidth();
  const [isMobile, setIsMobile] = useState(windowWidth < 1024);

  useEffect(() => {
    if (windowWidth < 1024) {
      if (!isMobile) {
        setIsMobile(true);
      }
    } else {
      if (isMobile) {
        setIsMobile(false);
      }
    }
  }, [windowWidth]);

  return (
    <section className="filter-slider container flex tablet">
      <SliderArrowIcon
        className="filter-arrow button mobile"
        role="button"
        tabIndex={0}
        aria-label="Next Slide"
        onClick={() => handleFilterClick(selected + 1)}
        onKeyPress={() => handleFilterClick(selected + 1)}
      />
      <div className="filter-bar" ref={filterContainerRef}>
        <div className="filter-track">
          {filters.map((button, index) => {
            return (
              <button
                key={index}
                className={`filter-button${
                  selected === button.order ? " selected" : ""
                }${hideZeroOption && button.order === 0 ? " mobile" : ""}`}
                onClick={() => handleFilterClick(button.order)}
              >
                {button.title}
              </button>
            );
          })}
        </div>
      </div>
      {children && !isMobile ? (
        <div className="desktop children-container">{children}</div>
      ) : null}
      {sliderChildren && isMobile ? (
        <AppSlider
          className="mobile"
          adaptiveHeight
          moreMargins
          hideArrowsTablet
          slideIndex={selected}
          setSlideIndex={setSelected}
          arrows={false}
        >
          {sliderChildren}
        </AppSlider>
      ) : null}
    </section>
  );
};

export default FilterSliderSection;
