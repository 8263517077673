import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import _ from "lodash";

const SEO = ({ author, title, header, description, image, isIndexPage }) => {
  const { site, contentfulSiteData } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
            favicon
            siteUrl
            lang
          }
        }
        contentfulSiteData {
          title
          description
          author
          image {
            file {
              url
            }
          }
          favicon {
            file {
              url
            }
          }
          siteUrl
          lang
        }
      }
    `
  );

  const { siteMetadata } = site;

  const defaultTitle = _.get(contentfulSiteData, "title") || siteMetadata.title;
  // const metaTitle = isIndexPage ? defaultTitle : title;
  const metaTitle = isIndexPage
    ? `${defaultTitle} | ${header}`
    : `${title} | ${defaultTitle}`;

  const defaultImage =
    _.get(contentfulSiteData, "image.file.url") ||
    siteMetadata.siteUrl + siteMetadata.image;
  const metaImage = _.get(image, "file.url") || defaultImage;

  const metaDescription =
    description ||
    _.get(contentfulSiteData, "description") ||
    siteMetadata.description;
  const metaAuthor =
    author || _.get(contentfulSiteData, "author") || siteMetadata.author;
  const metaLang = _.get(contentfulSiteData, "lang") || siteMetadata.lang;

  useEffect(() => {
    const icon =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    icon.type = "image/x-icon";
    icon.rel = "shortcut icon";
    icon.href =
      _.get(contentfulSiteData, "favicon.file.url") || siteMetadata.favicon;
    document.getElementsByTagName("head")[0].appendChild(icon);
  });

  return (
    <Helmet
      htmlAttributes={{
        lang: metaLang,
      }}
      title={isIndexPage ? header : title}
      defaultTitle={defaultTitle}
      titleTemplate={`${isIndexPage ? defaultTitle + " | " : ""}%s${
        isIndexPage ? "" : " | " + defaultTitle
      }`}
    >
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {/* General tags */}
      <meta name="image" content={metaImage} />
      <meta name="description" content={metaDescription} />

      {/* OpenGraph tags */}
      <meta property="og:title" content={metaTitle} />
      <meta property="og:image" content={metaImage} />
      <meta property="og:description" content={metaDescription} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:image" content={metaImage} />
      <meta name="twitter:description" content={metaDescription} />
      <meta property="twitter:creator" content={metaAuthor || ``} />
    </Helmet>
  );
};

export default SEO;
