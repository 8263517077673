import React from "react";

const DownArrowIcon = (props) => {
  return (
    <div {...props} className={"icon-wrapper down-arrow-icon"}>
      <svg
        // width={props.width || "100%"}
        // height={props.height || "100%"}
        role="none"
        height="100%"
        viewBox="0 0 14 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 -2.62268e-07L7 12M7 12L13 6M7 12L0.999999 6"
          stroke="currentColor"
        />
      </svg>
    </div>
  );
};

export default DownArrowIcon;
