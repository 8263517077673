import React, { useState } from "react";
import { AppImage, EllipseIcon } from "../components";

const ToggleCardAdvanced = (props) => {
  const [expand, setExpand] = useState(0);
  function handleClick(id) {
    if (id === expand) {
      setExpand(0);
    } else {
      setExpand(id);
    }
  }
  return (
    <>
      {props.data.map((card, index) => {
        return (
          <div
            key={index}
            className={`toggleCardAdvancedContainer toggleCard ${
              expand === card.order ? " expanded" : ""
            }`}
            role="button"
            tabIndex={0}
            aria-label={card.title}
            onClick={() => handleClick(card.order)}
            onKeyPress={() => handleClick(card.order)}
          >
            <div className="toggleTitle">
              <AppImage className="toggleCardAdvancedIcon" image={card.icon} />
              <h2 className="h3 toggleCardAdvancedTitle">{card.title}</h2>
            </div>
            {expand === card.order && (
              <div
                className="toggleCardAdvancedDescription"
                dangerouslySetInnerHTML={{
                  __html: card.description.childMarkdownRemark.html,
                }}
              />
            )}
            {expand !== card.order && (
              <EllipseIcon className="toggledCardAdvancedEllipse" />
            )}
          </div>
        );
      })}
    </>
  );
};

export default ToggleCardAdvanced;
