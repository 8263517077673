import React from "react";

const LinkedInIcon = (props) => {
  return (
    <div {...props} className={"icon-wrapper link-icon"}>
      <svg
        role="none"
        width="100%"
        height="100%"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.1374 10.8628C22.6382 14.3672 22.5902 19.9857 19.1585 23.4364C19.1521 23.4434 19.1444 23.451 19.1374 23.4581L15.1999 27.3955C11.7271 30.8684 6.07695 30.8679 2.60463 27.3955C-0.868211 23.9233 -0.868211 18.2725 2.60463 14.8003L4.77881 12.6261C5.35537 12.0495 6.3483 12.4327 6.37806 13.2475C6.41603 14.2859 6.60224 15.3292 6.94584 16.3366C7.0622 16.6778 6.97906 17.0551 6.72418 17.31L5.95736 18.0768C4.31522 19.719 4.26371 22.3928 5.88969 24.051C7.53171 25.7256 10.2307 25.7355 11.8853 24.0809L15.8228 20.144C17.4746 18.4922 17.4677 15.8223 15.8228 14.1774C15.6059 13.961 15.3875 13.7928 15.2169 13.6753C15.0961 13.5924 14.9965 13.4825 14.9258 13.3542C14.8551 13.226 14.8154 13.083 14.8098 12.9367C14.7866 12.3175 15.006 11.6795 15.4952 11.1902L16.7289 9.95655C17.0524 9.63306 17.5598 9.59333 17.935 9.85513C18.3645 10.1551 18.7669 10.4923 19.1374 10.8628ZM27.3953 2.60446C23.923 -0.867918 18.2729 -0.868387 14.8 2.60446L10.8625 6.54195C10.8555 6.54898 10.8479 6.5566 10.8414 6.56363C7.40984 10.0143 7.36173 15.6328 10.8625 19.1372C11.233 19.5077 11.6354 19.8449 12.0649 20.1448C12.44 20.4066 12.9476 20.3668 13.271 20.0434L14.5047 18.8097C14.9939 18.3204 15.2133 17.6824 15.1901 17.0633C15.1845 16.9169 15.1447 16.774 15.0741 16.6457C15.0034 16.5175 14.9037 16.4075 14.783 16.3246C14.6124 16.2071 14.394 16.039 14.1771 15.8225C12.5322 14.1776 12.5253 11.5078 14.1771 9.85595L18.1146 5.91904C19.7692 4.26442 22.4681 4.27438 24.1102 5.94892C25.7362 7.60712 25.6847 10.281 24.0425 11.9231L23.2757 12.6899C23.0208 12.9448 22.9377 13.3222 23.054 13.6633C23.3976 14.6708 23.5839 15.714 23.6218 16.7524C23.6516 17.5672 24.6445 17.9504 25.2211 17.3739L27.3952 15.1997C30.8682 11.7275 30.8682 6.07672 27.3953 2.60446Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export default LinkedInIcon;
