import { useState, useEffect } from "react";

function getWindowWidth(time) {
  const isBrowser = typeof window !== "undefined";
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);

  function debounce(func, ms) {
    let timer;
    return () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(this, arguments);
      }, ms);
    };
  }

  useEffect(() => {
    if (!isBrowser) {
      return false;
    }

    const debouncedHandleResize = debounce(function handleResize() {
      setWidth(window.innerWidth);
    }, time || 100);

    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  return width;
}

export default getWindowWidth;
