import React from "react";

const TwitterIcon = (props) => {
  return (
    <div {...props} className={"icon-wrapper facebook-icon"}>
      <svg
        // width={props.width || "100%"}
        // height={props.height || "100%"}
        role="none"
        width="100%"
        height="100%"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.275 17.25C13.8272 17.25 17.9566 10.9968 17.9566 5.57722C17.9566 5.39858 17.9566 5.21988 17.9481 5.04973C18.7484 4.47122 19.4466 3.74804 20 2.92278C19.2678 3.24609 18.4759 3.46731 17.6416 3.5694C18.493 3.0589 19.1401 2.25916 19.4466 1.29778C18.6548 1.7657 17.7778 2.10606 16.8412 2.29319C16.0919 1.49346 15.0277 1 13.8442 1C11.5794 1 9.74033 2.83773 9.74033 5.1008C9.74033 5.42412 9.77439 5.73888 9.851 6.03666C6.43678 5.86651 3.41422 4.23301 1.38783 1.74871C1.03872 2.35276 0.834389 3.0589 0.834389 3.80761C0.834389 5.22843 1.55811 6.48759 2.66494 7.21927C1.99233 7.20222 1.36228 7.01509 0.808833 6.70876V6.75983C0.808833 8.75067 2.22222 10.4012 4.10389 10.784C3.76333 10.8776 3.39722 10.9287 3.02256 10.9287C2.75861 10.9287 2.50322 10.9032 2.24778 10.8521C2.76717 12.4856 4.28267 13.6682 6.07917 13.7022C4.67433 14.7998 2.90339 15.4549 0.979167 15.4549C0.647111 15.4549 0.323556 15.4379 0 15.3953C1.7965 16.5694 3.95911 17.25 6.275 17.25Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export default TwitterIcon;
