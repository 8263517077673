import React, { useEffect } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import { CloseIcon } from "../components";

const Modal = ({ showModal, handleClick, className, children }) => {
  // Make sure modal does not exist until it needs to (showModal && <Modal />)
  let targetElement;
  const insideTextModal = [];
  const isMobileIosSafari =
    navigator.userAgent.indexOf("Safari") != -1 &&
    navigator.userAgent.indexOf("iPhone") != -1;

  useEffect(() => {
    // Select id of the container of the actual modal, modal-wrapper container.
    targetElement = document.querySelector("#modal-wrapper-container");
    if (isMobileIosSafari) {
      document
        .querySelectorAll(`#modal-wrapper-container *`)
        .forEach((node) => insideTextModal.push(node));
      window.addEventListener("touchmove", handleTouchMove, { passive: false });
    } else {
      disableBodyScroll(targetElement);
    }
    // Destroy listener upon leaving page i.e. back button.
    return () => {
      if (isMobileIosSafari) {
        window.removeEventListener("touchmove", handleTouchMove, {
          passive: false,
        });
      } else {
        enableBodyScroll(targetElement);
      }
    };
  });

  const handleTouchMove = (e) => {
    if (
      !(e.target.id === targetElement.id || insideTextModal.includes(e.target))
    ) {
      e.preventDefault();
    }
  };

  const handleCloseModal = () => {
    if (isMobileIosSafari) {
      window.removeEventListener("touchmove", handleTouchMove, {
        passive: false,
      });
    } else {
      enableBodyScroll(targetElement);
    }
    handleClick();
  };

  return (
    <div>
      {showModal ? (
        <React.Fragment>
          <div className={`modal-overlay ${className || ""}`}>
            <div
              className="modal-disabled-area"
              role="none"
              onClick={() => handleCloseModal(showModal)}
            />
            <div
              className="modal-wrapper"
              id="modal-wrapper-container"
              aria-modal
              aria-hidden
              tabIndex={-1}
              role="dialog"
            >
              <div className="modal-header">
                <div>
                  {/*<span aria-hidden="true">*/}
                  <CloseIcon
                    role="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    tabIndex={0}
                    onClick={() => handleCloseModal(showModal)}
                    onKeyPress={() => handleCloseModal(showModal)}
                  />
                  {/*</span>*/}
                </div>
              </div>
              <div className="modal-container">{children}</div>
            </div>
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default Modal;
