import React from "react";
import { Link } from "gatsby";
// import { useStaticQuery, graphql } from "gatsby";
// import _ from "lodash";

import formatLinkHandle from "../utils/formatLinkHandle";

const CookieModal = ({ cookieModalHandler }) => {
  return (
    <div className="cookie-modal">
      <div className="modal-text">
        This website uses cookies and third party services. By using this
        website, you agree to the collection and use of information in
        accordance with our{" "}
        <Link to={formatLinkHandle("privacy-policy")}>Privacy Policy.</Link>
      </div>
      <button className="modal-button" onClick={cookieModalHandler}>
        Accept
      </button>
    </div>
  );
};

export default CookieModal;
