import React from "react";

const DiagonalArrowIcon = (props) => {
  const svg = () => {
    return (
      <svg
        // width={props.width || "100%"}
        // height={props.height || "100%"}
        role="none"
        height="100%"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 11L11 1M11 1H1M11 1V11" stroke="currentColor" />
      </svg>
    );
  };
  if (props.href) {
    return (
      <a {...props} className={"icon-wrapper diagonal-arrow-icon"}>
        {svg()}
      </a>
    );
  }
  return (
    <div {...props} className={"icon-wrapper diagonal-arrow-icon"}>
      {svg()}
    </div>
  );
};

export default DiagonalArrowIcon;
