import React from "react";

const EllipseIcon = (props) => {
  const { className } = props;
  return (
    <div
      {...props}
      className={`icon-wrapper ellipse-icon${className ? ` ${className}` : ""}`}
    >
      <svg
        role="none"
        width="6"
        height="6"
        viewBox="0 0 6 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="3" cy="3" r="3" fill="currentColor" />
      </svg>
    </div>
  );
};

export default EllipseIcon;
